"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
// import logo from './logo.svg';
require("./../App.css");
const react_1 = __importStar(require("react"));
const Sidebar = (props) => {
    return (react_1.default.createElement("div", { className: props.className },
        react_1.default.createElement("span", { style: { display: "block", marginBottom: "25px" } }, "D I A L E R"),
        react_1.default.createElement("br", null),
        react_1.default.createElement("span", null, props.dialedNum),
        react_1.default.createElement("br", null),
        react_1.default.createElement("span", null, "@ b j n . v c")));
};
const DialButton = (props) => {
    return (react_1.default.createElement("div", { className: props.className, onClick: (e) => { props.onClick(props.value); } }, props.value));
};
const CallButton = (props) => {
    return (react_1.default.createElement("div", { className: props.className, onClick: (e) => { props.onCallClick(); } }, "C A L L"));
};
const Dialpad = (props) => {
    const buttonArr = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];
    const buttonComponents = buttonArr.map((x) => {
        return (react_1.default.createElement(DialButton, { key: x, className: "dial-button", value: x, onClick: props.onClick }));
    });
    return (react_1.default.createElement("div", { className: props.className },
        buttonComponents,
        react_1.default.createElement(CallButton, { className: "call-button", onCallClick: props.onCallClick })));
};
const BJPage = (props) => {
    return (react_1.default.createElement("iframe", { className: props.className, src: props.src, allow: "microphone; camera; encrypted-media" }));
};
const App = (props) => {
    const [dialedNum, setDialedNum] = (0, react_1.useState)("");
    const [frameSource, setFrameSource] = (0, react_1.useState)("");
    const [pageVisibility, setPageVisibility] = (0, react_1.useState)("bj-page page-hidden");
    function _handleDialingNum(num) {
        let lastNum = dialedNum;
        if (num === "*")
            lastNum = lastNum.length > 0 ? lastNum.slice(0, lastNum.length - 1) : lastNum = "";
        else
            lastNum += num;
        setDialedNum(lastNum);
    }
    function _handleCall() {
        if (dialedNum != "") {
            setFrameSource("https://bluejeans.com/" + dialedNum + "/?embed=true&fullscreen=true");
            setPageVisibility("bj-page page-show");
        }
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(BJPage, { className: pageVisibility, src: frameSource }),
        react_1.default.createElement(Sidebar, { className: "sidebar", dialedNum: dialedNum }),
        react_1.default.createElement(Dialpad, { className: "dialpad", onClick: _handleDialingNum, onCallClick: _handleCall })));
};
export default App;
